import { LoadingButton } from "@mui/lab";
import { Alert, Box, Container, ContainerProps, Paper } from "@mui/material";

import { Form, useFetcher } from "react-router-dom";

import { localize } from "~/localize";

import { LearnLogo } from "./Logo";
import { NavigationIndicator } from "./NavigationIndicator";

export function FormContainer({
	children,
	error,
	language,
	...props
}: ContainerProps & { error?: string; language: "en-GB" | "nb-NO" }) {
	let fetcher = useFetcher();

	return (
		<Container maxWidth="sm" {...props}>
			<NavigationIndicator />
			<Paper sx={{ py: 2 }}>
				<LearnLogo />
				{error && (
					<Alert severity="error" sx={{ mb: 2 }}>
						{error}
					</Alert>
				)}
				<Form method="post" action=".">
					<Container maxWidth="xs" sx={{ display: "flex", flexDirection: "column", gap: 3, justifyContent: "center" }}>
						{children}
					</Container>
				</Form>
				<Box my={2} textAlign="center">
					<fetcher.Form method="POST" action="/set-language">
						<input type="hidden" name="language" value={language === "en-GB" ? "nb-NO" : "en-GB"} />
						<LoadingButton loading={fetcher.state !== "idle"} type="submit">
							{localize("Switch to english", "Bytt til norsk")}
						</LoadingButton>
					</fetcher.Form>
				</Box>
			</Paper>
		</Container>
	);
}
